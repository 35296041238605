<template>
    <div class="TransferOfMoney">



        <a-tabs v-model:activeKey="activeKey" type="card">
            <a-tab-pane key="1" tab="单笔转账"></a-tab-pane>
            <a-tab-pane key="4" tab="批量制单" :disabled="user.roule !== 0 && user.roule !== 1"></a-tab-pane>
            <a-tab-pane key="2" tab="打款列表"></a-tab-pane>
            <!-- <a-tab-pane key="3" tab="多笔转账" :disabled="user.roule !== 0"></a-tab-pane> -->
        </a-tabs>


        <div class="clearfix" v-if="activeKey === '4'">
            <a-upload-dragger v-model:file-list="fileList" list-type="picture" :before-upload="beforeUpload"
                @remove="handleRemove" :max-count="1">
                <p class="ant-upload-drag-icon">
                    <inbox-outlined></inbox-outlined>
                </p>
                <p style="margin: 10px;">点击或拖拽上传</p>

                <a-button style="margin: 20px 0;" type="primary" @click.stop="DownloadTemplateZD">下载批量制单模板</a-button>
            </a-upload-dragger>
            <a-button type="primary" :disabled="fileList.length === 0" :loading="uploading" style="margin-top: 16px"
                @click="handleUpload(1)">
                {{ uploading ? '正在加载' : '确认制单' }}
            </a-button>
        </div>


        <div class="clearfix" v-if="activeKey === '3'">
            <a-upload-dragger v-model:file-list="fileList" list-type="picture" :before-upload="beforeUpload"
                @remove="handleRemove" :max-count="1">
                <p class="ant-upload-drag-icon">
                    <inbox-outlined></inbox-outlined>
                </p>
                <p style="margin: 10px;">点击或拖拽上传</p>

                <a-button style="margin: 20px 0;" type="primary" @click.stop="DownloadTemplate">下载批量支付模板</a-button>
            </a-upload-dragger>
            <a-button type="primary" :disabled="fileList.length === 0" :loading="uploading" style="margin-top: 16px"
                @click="handleUpload(0)">
                {{ uploading ? '正在加载' : '确认转账' }}
            </a-button>
        </div>



        <div v-if="activeKey === '2'">
            <div class="rolemanagement_box">
                <a-table class="components-table-demo-nested" :columns="columns" :data-source="list" border
                    :scroll="{ y: 580 }" :pagination="false">

                    <template #bodyCell="{ column, record }">
                        <template v-if="column.dataIndex === 'amount'">
                            <!-- <a-button type="primary" @click="StatusQueryFun(record)">查询状态</a-button> -->
                            <span style="color:red;">{{ record.amount }}</span>
                        </template>
                        <template v-if="column.dataIndex === 'Controls'">
                            <a-space warp>
                                <a-button type="primary" v-if="user.roule === 1 || user.roule === 0"
                                    :disabled="record.status === '2' ? true : false"
                                    @click="api_cw_cancle_dkFun(record)">
                                    取消制单
                                </a-button>
                                <a-button type="primary" v-if="(user.roule === 3 || user.roule === 0)"
                                    :disabled="record.status === '1' ? false : true" @click="MakePaymentFun(record)">
                                    去审核
                                </a-button>
                            </a-space>
                        </template>
                    </template>
                </a-table>
            </div>
            <a-pagination style=" margin-top: 20px;" v-model:current="current" simple :total="total"
                @change="paginationChange" :pageSize="10" :hideOnSinglePage="false" />


        </div>



















        <a-form v-if="activeKey === '1'" :model="formState" :rules="rules" name="basic" :label-col="{ span: 2 }"
            :wrapper-col="{ span: 16 }" autocomplete="off" @finish="api_cw_document_preparationFun"
            @finishFailed="onFinishFailed" :layout="layout">



            <a-divider orientation="left">付款方信息</a-divider>
            <a-form-item label="本方账户名称" name="payAccName" :rules="[{ required: true, message: '本方账户名称不得为空' }]">
                <a-input v-model:value="formState.payAccName" />
            </a-form-item>

            <a-form-item label="本方账户" name="payAccNo" :rules="[{ required: true, message: '本方账户不得为空' }]">
                <a-input v-model:value="formState.payAccNo" />
            </a-form-item>

            <a-form-item label="转账金额" name="amount"
                :rules="[{ required: true, message: '转账金额不得为空', trigger: 'change' }]">
                <a-input size="large" placeholder="请输入转账金额" v-model:value="formState.amount" />
            </a-form-item>


            <a-divider orientation="left">收款方信息</a-divider>

            <a-form-item label="收款账户名称" name="recAccName" :rules="[{ required: true, message: '收款账户名称不得为空' }]">
                <a-input v-model:value="formState.recAccName" />
            </a-form-item>

            <a-form-item label="收款账户" name="recAccNo" :rules="[{ required: true, message: '收款账户不得为空' }]">
                <a-input v-model:value="formState.recAccNo" />
            </a-form-item>

            <a-form-item label="行联号" v-if="formState.isSameBank === '2'" name="recBankNo"
                :rules="[{ required: true, message: '行联号不得为空' }]">
                <a-input v-model:value="formState.recBankNo" />
            </a-form-item>
            <a-form-item label="银行名称" v-if="formState.isSameBank === '2'" name="recBankName"
                :rules="[{ required: true, message: '银行名称不得为空' }]">
                <a-input v-model:value="formState.recBankName" />
            </a-form-item>

            <a-divider orientation="left">其他信息</a-divider>

            <a-form-item label="是否跨行" name="isSameBank" :rules="[{ required: true }]">
                <a-radio-group v-model:value="formState.isSameBank">
                    <a-radio value="1">否</a-radio>
                    <a-radio value="2">是</a-radio>
                </a-radio-group>
            </a-form-item>

            <a-form-item label="附言" name="memo">
                <a-input v-model:value="formState.memo" />
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 1, span: 1 }">
                <a-button type="primary" html-type="submit" style="width: 100%;"
                    :disabled="(user.roule === 0 || user.roule === 1) ? false : true">制单</a-button>
            </a-form-item>
        </a-form>







        <a-modal v-model:open="dakuanOpen" title="确认转账吗？确认后金额立即转出" okText="确认" cancelText="关闭" @ok="zhuanzhangFun">




            <a-space>
                <a-select ref="select" v-model:value="auditType" style="width: 120px">
                    <a-select-option value="1">同意</a-select-option>
                    <a-select-option value="0">拒绝</a-select-option>
                </a-select>
            </a-space>


        </a-modal>

        <a-modal v-model:open="shenheOpen" title="打款操作" okText="确认" cancelText="取消" @ok="api_cw_to_examineFun">

            <a-space>
                <a-select ref="select" v-model:value="auditType" style="width: 120px">
                    <a-select-option value="-1">拒绝</a-select-option>
                    <a-select-option value="1">同意</a-select-option>
                </a-select>
            </a-space>

        </a-modal>





    </div>
</template>

<script lang="ts">
import { reactive, ref, customRef, onMounted, watch, h } from 'vue';
// import { message } from 'ant-design-vue';
import {
    api_cw_single_pay,
    api_cw_pl_pay,
    api_cw_document_preparation,
    api_cw_get_zd_list,
    api_cw_to_examine,
    api_index_pl_zd,
    api_cw_jujue_dk,
    api_cw_cancle_dk
} from '@/api/home'
import { UploadOutlined, InboxOutlined } from '@ant-design/icons-vue';
import type { UploadChangeParam, UploadProps } from 'ant-design-vue';


import { Modal } from 'ant-design-vue';
import { message, type TableColumnsType } from 'ant-design-vue';
import router from "@/router";
import { useStore } from 'vuex';

export default {
    name: 'TransferOfMoney',
    components: {
        UploadOutlined,
        InboxOutlined
    },
    setup() {
        interface FormState {
            payAccName: string;
            payAccNo: string;
            recAccName: string;
            recAccNo: string;
            amount: number | null;
            isSameBank: string,
            recBankNo: string;
            recBankName: string;
            openid: string;
            memo: string;
        }

        let user: any = sessionStorage.getItem('user');
        user = JSON.parse(user);

        const formState = reactive<FormState>({
            payAccName: user.payAccName,
            payAccNo: user.payAccNo,
            recAccName: '',
            recAccNo: '',
            amount: null,
            isSameBank: "1",
            recBankNo: '',
            recBankName: '',
            openid: user.openid,
            memo: ''
        });


        const store = useStore();



        onMounted(() => {
            let user = JSON.parse(store.state.user);
            console.log(user);
        })


        const api_cw_document_preparationFun = async (values: FormState) => {
            values.openid = user.openid
            let data: any = await api_cw_document_preparation(values);
            console.log(data);

            if (data.error === 0) {
                setTimeout(() => {
                    router.push({ path: '/home' });
                }, 1000)
            }
        };






        const onFinishFailed = () => {
            message.error('校验未通过请检查参数');
        };








        const fileList: any = ref<UploadProps['fileList']>([]);



        const handleChange = (info: any) => {
            const status = info.file.status;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            console.log(info, '-------------------');

            if (status === 'done') {
                // message.success(`${info.file.name} 上传成功`);
                if (info.file.response.error === 0) {
                    if (info.file.response.message) message.success(info.file.response.message);
                } else {
                    if (info.file.response.message) message.error(info.file.response.message);
                }
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        };
        function handleDrop(e: DragEvent) {
            console.log(e);
        }


















        const uploading = ref<boolean>(false);


        const handleRemove: UploadProps['onRemove'] = file => {
            const index = fileList.value.indexOf(file);
            const newFileList = fileList.value.slice();
            newFileList.splice(index, 1);
            fileList.value = newFileList;
        };

        const beforeUpload: UploadProps['beforeUpload'] = file => {
            fileList.value = [...(fileList.value || []), file];
            return false;
        };

        const handleUpload = async (type: number) => {
            const formData = new FormData();
            let file = fileList.value[0].originFileObj;
            formData.append('file', file);
            formData.append('openid', user.openid);
            let data = null;
            if (type === 0) {
                uploading.value = true;
                data = await api_cw_pl_pay(formData);
            } else {
                data = await api_index_pl_zd(formData);
            }
            uploading.value = false;
            fileList.value = [];

        };


        let activeKey = ref('1')



        const DownloadTemplate = () => {
            window.location.href = 'http://newappadmin.zhongzihuacai.com/cer/pay.xls';
        }

        const DownloadTemplateZD = () => {
            window.location.href = 'https://cw.jibeimall.com/zd.xlsx';
        }


        watch(activeKey, (newValue, oldValue) => {
            console.log(newValue);


            api_cw_get_zd_listFun();



        })



        let current = ref<number>(1);
        let total: any = ref(1)
        let list = ref<any>([]);

        const api_cw_get_zd_listFun = async () => {
            list.value = [];
            let up = {
                openid: user.openid,
                pageNo: current.value
            }
            let data: any = await api_cw_get_zd_list(up);
            if (data.error === 0) {
                data.list.forEach((item: any) => {
                    let obj = {
                        recAccNo: item.recAccNo,
                        recAccName: item.recAccName,
                        isSameBank: item.isSameBank === '1' ? '否' : '是',
                        amount: item.amount,
                        memo: item.memo,
                        recBankNo: item.recBankNo ? item.recBankNo : '-',
                        recBankName: item.recBankName ? item.recBankName : '-',
                        status_name: item.status_name,
                        status: item.status,
                        time: item.time,
                        item
                    }
                    list.value.push(obj);
                })
            }
            console.log(data);
            total.value = data.total * 1;
        }



        const columns: TableColumnsType = [
            { title: '收款人账号', width: 200, dataIndex: 'recAccNo' },
            { title: '收款户名', width: 100, dataIndex: 'recAccName' },
            { title: '是否跨行', width: 100, dataIndex: 'isSameBank' },
            { title: '打款金额', width: 100, dataIndex: 'amount' },
            { title: '附言', width: 100, dataIndex: 'memo' },
            { title: '行号', width: 100, dataIndex: 'recBankNo' },
            { title: '银行名称', width: 100, dataIndex: 'recBankName' },
            { title: '状态', width: 100, dataIndex: 'status_name' },
            { title: '操作时间', width: 200, dataIndex: 'time' },
            {
                title: '操作', dataIndex: 'Controls',
            },
        ];




        // recAccNo  收款人账号
        // recAccName  收款户名
        // isSameBank  是否跨行（1同行  2跨行）
        // amount  打款金额
        // memo  附言
        // recBankNo  行号
        // recBankName   银行名称
        // status_name  状态




        const paginationChange = (e: number) => {
            current.value = e;
            api_cw_get_zd_listFun();
        }



        const MakePaymentFun = (e: any) => {

            if (e.status === '0') {
                shenheOpen.value = !shenheOpen.value;
            } else {
                dakuanOpen.value = !dakuanOpen.value;
            }
            TemporaryStorage = e.item;
            // console.log(TemporaryStorage);
        };
        let TemporaryStorage = reactive<any>({});

        let zhuanzhangFun = () => {
            if (auditType.value === '0') {
                api_cw_jujue_dkFun()
            } else {
                api_cw_single_payFun()
            }
        }

        //同意打款
        const api_cw_single_payFun = async () => {
            let up = {
                payAccName: user.payAccName,
                payAccNo: user.payAccNo,
                recAccName: TemporaryStorage.recAccName,
                recAccNo: TemporaryStorage.recAccNo,
                amount: TemporaryStorage.amount,
                isSameBank: TemporaryStorage.isSameBank,
                recBankNo: TemporaryStorage.recBankNo,
                recBankName: TemporaryStorage.recBankName,
                openid: user.openid,
                memo: TemporaryStorage.memo,
                id: TemporaryStorage.id
            }
            let data: any = await api_cw_single_pay(up);
            console.log(data);
            if (data.error === 0) {
                // setTimeout(() => {
                // router.push({ path: '/home' });
                // }, 1000)
                api_cw_get_zd_listFun()
            }
            dakuanOpen.value = !dakuanOpen.value;
            auditType.value = '0'
        };

        //取消或拒绝
        let api_cw_jujue_dkFun = async () => {
            let up = {
                openid: user.openid,
                id: TemporaryStorage.id
            }
            let data: any = await api_cw_jujue_dk(up);
            if (data.error === 0) {
                api_cw_get_zd_listFun();
            }
            dakuanOpen.value = !dakuanOpen.value;
            auditType.value = '0'

        }


        let dakuanOpen = ref(false);
        let shenheOpen = ref(false);

        let auditType = ref<string>('0')

        const api_cw_to_examineFun = async () => {
            let up = {
                openid: user.openid,
                id: TemporaryStorage.id,
                type: auditType.value
            }
            let data: any = await api_cw_to_examine(up);
            if (data.error === 0) {
                api_cw_get_zd_listFun();
            }
            shenheOpen.value = !shenheOpen.value;
        }



        const api_cw_cancle_dkFun = async (item: any) => {

            let up = {
                openid: user.openid,
                id: item.item.id
            }
            let data: any = await api_cw_cancle_dk(up);
            if (data.error === 0) {
                api_cw_get_zd_listFun();
            }
        }



        return {
            TemporaryStorage,
            dakuanOpen,
            shenheOpen,
            auditType,

            handleRemove,
            zhuanzhangFun,
            beforeUpload,
            handleUpload,
            uploading,
            fileList,

            list,
            current,
            total,
            columns,

            activeKey,
            handleDrop,

            handleChange,


            api_cw_document_preparationFun,
            api_cw_cancle_dkFun,
            DownloadTemplate,
            DownloadTemplateZD,
            user,
            formState,
            api_cw_single_payFun,
            onFinishFailed,
            paginationChange,
            MakePaymentFun,
            api_cw_to_examineFun,
            rules: {
                amount: [{ type: 'number', required: true, message: '转账金额必须是数字', trigger: 'change' }]
            },
            layout: 'vertical'
        }


    }
}




</script>

<style lang="less" scoped>
.TransferOfMoney {
    width: 100%;
    height: 100%;
}
</style>