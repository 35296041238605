import ajax from './request';

//单笔支付接口
interface API_CW_SINGLE_PAY {
    payAccNo: string, //本方账户
    payAccName: string,  //本方账户名称
    recAccNo: string, //收款账户 
    recAccName: string,  //收款 账户名称
    amount: number | null, //金额
    isSameBank: string, //跨行
    memo: string
}


//财务 账号明细查询
interface API_CW_GET_BALANCE_LIST {
    accNo: string,  //查询银行账号
    pageNo: number, //分页 （从1开始）
    beginDate: number | string,  //开始时间
    endDate: number | string, //结束时间
    openid: string,
    status?: string,
    packageNo?: string,
}

//回单查询
interface API_CW_HD_CHECK {
    openid: string,
    accNo: string  //账号
    retNo: string  //电子回单号
}

//状态查询 api_cw_pay_check
interface API_CW_PAY_CHECK {
    [k: string]: string | number | null
}

// 财务 确认打款 操作接口 api.cw.dk_cl
export function api_cw_dk_cl(data: API_CW_PAY_CHECK) {
    return ajax('api.cw.dk_cl', 'POST', data)
}

//数农财务取消打款 api.cw.cancle_dk
export function api_cw_cancle_dk(data: API_CW_PAY_CHECK) {
    return ajax('api.cw.cancle_dk', 'POST', data)
}


// payAccNo   默认显示：1013853200000122
// payAccName   海南交互农产品电子商务有限公司（测试账户）
// recAccNo   1005344000000139
// recAccName   3476918
// isSameBank  //  1同行  2跨行
// amount   //付款金额 （单位元）

//单笔支付接口 api.cw.single_pay
export function api_cw_single_pay(data: API_CW_SINGLE_PAY) {
    return ajax('api.cw.single_pay', 'POST', data)
}

//拒绝支付接口 api.cw.jujue_dk 
export function api_cw_jujue_dk(data: API_CW_PAY_CHECK) {
    return ajax('api.cw.jujue_dk', 'POST', data)
}




// 财务   - 制单员制单（单笔打款）api.cw.document_preparation
export function api_cw_document_preparation(data: API_CW_SINGLE_PAY) {
    return ajax('api.cw.document_preparation', 'POST', data)
}

//余额查询  api.cw.balance_inquiry
export function api_cw_balance_inquiry(accNo: string, openid: string) {
    return ajax('api.cw.balance_inquiry', 'POST', { accNo, openid })
}







// 财务 账号明细查询 api.cw.get_balance_list
export function api_cw_get_balance_list(data: API_CW_GET_BALANCE_LIST) {
    return ajax('api.cw.get_balance_list', "POST", data)
}

// 财务 账号明细查询 api.cw.get_balance_list
export function api_cw_get_balance_lists(data: API_CW_GET_BALANCE_LIST) {
    return ajax('api.cw.get_balance_list', "POST", data, false)
}

//财务明细查询接口 api.index.get_finance_list
export function api_cw_get_finance_list(data: API_CW_GET_BALANCE_LIST) {
    return ajax('api.cw.get_finance_list', 'POST', data)
}



//批量支付接口 api.cw.pl_pay
export function api_cw_pl_pay(data: any) {
    return ajax('api.cw.pl_pay', 'POST', data)
}

//数农财务批量制单接口 api.index.pl_zd
export function api_index_pl_zd(data: any) {
    return ajax('api.cw.pl_zd', 'POST', data)
}

//回单查询 api.cw.hd_check
export function api_cw_hd_check(data: API_CW_HD_CHECK) {
    return ajax('api.cw.hd_check', "POST", data)
}



//状态查询 api.cw.pay_check
export function api_cw_pay_check(data: API_CW_PAY_CHECK) {
    return ajax('api.cw.pay_check', "POST", data)
}
//财务端  子账号 创建|更新 api.cw.add_account
export function api_cw_add_account(data: API_CW_PAY_CHECK) {
    return ajax('api.cw.add_account', "POST", data)
}

// 财务端  显示子账号列表 api.index.get_account_list
export function api_index_get_account_list(data: API_CW_PAY_CHECK) {
    return ajax('api.cw.get_account_list', "POST", data)
}

//财务 子账号删除 api.cw.del_account
export function api_cw_del_account(data: API_CW_PAY_CHECK) {
    return ajax('api.cw.del_account', "POST", data)
}

//财务端  制单列表查看 api.cw.get_zd_list
export function api_cw_get_zd_list(data: API_CW_PAY_CHECK) {
    return ajax('api.cw.get_zd_list', "POST", data)
}

//打款列表  审核员审核 操作 api.cw.to_examine
export function api_cw_to_examine(data: API_CW_PAY_CHECK) {
    return ajax('api.cw.to_examine', "POST", data)
}

// 财务端  加一个设置操作，点击进去 是修改 账户或者密码的 api.cw.set_info
export function api_cw_set_info(data: API_CW_PAY_CHECK) {
    return ajax('api.cw.set_info', "POST", data)
}

//api.cw.get_notice 财务首页消息提醒
export function api_cw_get_notice(openid: string) {
    return ajax('api.cw.get_notice', "POST", { openid })
}

//财务首页获取用户信息 api.cw.get_user_info
export function api_cw_get_user_info(openid: string) {
    return ajax('api.cw.get_user_info', "POST", { openid })
}